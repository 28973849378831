// @mui
import { Link as RouterLink } from 'react-router-dom';
import { Divider, IconButton, Stack, Typography, Box, Button, Link } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import { BRAND } from '../../config-global';
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
import isLocalhost from '../../utils/functions';
import useAnalytics from '../../hooks/useAnalytics';
import { GA } from '../../constants/ga-events';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { trackEvent } = useAnalytics();
  const handleLogin = () => {
    trackEvent(GA.EVENT_NAME.insta_login_btn, {
      category: GA.CATEGORY.button_click,
    });
    const redirect_uri = isLocalhost()
      ? 'https://localhost:3030/auth/verify'
      : 'https://texthauler.com/auth/verify';

    const redirectUrl = `https://www.instagram.com/oauth/authorize?client_id=1026593999024124&redirect_uri=${redirect_uri}&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments`;
    window.open(redirectUrl, '_self');
  };
  return (
    <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
      <Typography variant="body2">
        {BRAND.name} requires certain permissions to build automations with Instagram. Click the
        button to signup and grant them.
      </Typography>
      <Button
        size="medium"
        variant="contained"
        sx={{ mt: 4, width: '100%' }}
        startIcon={<Iconify icon="mynaui:brand-instagram" width={16} color="white" />}
        color="secondary"
        onClick={handleLogin}
      >
        <Typography variant="body1" fontSize={12}>
          Continue with Instagram
        </Typography>
      </Button>
      <Typography variant="body1" fontSize={12} sx={{ mt: 4 }}>
        By signing up, you agree to {BRAND.name}{' '}
        <Link
          component={RouterLink}
          to={PATH_PAGE.tos}
          color="#0084ff"
          variant="body1"
          fontSize={12}
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          component={RouterLink}
          to={PATH_PAGE.privacy}
          color="#0084ff"
          variant="body1"
          fontSize={12}
        >
          Privacy Policy
        </Link>
      </Typography>
      <div>
        <Typography variant="body1" fontSize={10} sx={{ mt: 16 }}>
          This application uses{' '}
          <Link
            href="https://developers.facebook.com/docs/instagram-platform/instagram-api-with-instagram-login"
            color="#0084ff"
            variant="body1"
            fontSize={10}
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram API with Instagram Login
          </Link>
          , developed and maintained by Meta, to ensure secure and seamless access to your account.
        </Typography>
        <Iconify icon="logos:meta" width={60} />
      </div>
    </Box>
  );
}

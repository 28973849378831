import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { m, useScroll, useSpring } from 'framer-motion';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
// sections
import HomeHero from '../sections/home/tempHomeHero';
import Footer from '../layouts/main/Footer';
// ----------------------------------------------------------------------

// config
import { BRAND } from '../config-global';
import useLeads from '../hooks/useLeads';

// ----------------------------------------------------------------------

export default function HomePage() {
  const theme = useTheme();

  const { scrollYProgress } = useScroll();

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const progress = (
    <m.div
      style={{
        top: 0,
        left: 0,
        right: 0,
        height: 3,
        zIndex: 1999,
        position: 'fixed',
        transformOrigin: '0%',
        backgroundColor: theme.palette.primary.main,
        scaleX,
      }}
    />
  );

  const { updateLeadClk } = useLeads();

  useEffect(() => {
    updateLeadClk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Alphtec</title>
      </Helmet>

      {/* {progress} */}

      <HomeHero />
      <Footer />

      {/* <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <HomeDemo />
        <HomeHauler />
        <HomeForDesigner />
        <HomeHaulerBottom />
        <HomeLookingFor />
      </Box> */}
    </>
  );
}

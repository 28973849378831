import { useLocation } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import { UPDATE_LEAD_CLK_API } from '../config/endpoints';

// ----------------------------------------------------------------------

export default function useLeads() {
  const query = new URLSearchParams(useLocation().search);

  const updateLeadClk = async () => {
    const ref_id = query.get('ref_id');
    const ld_id = query.get('ld_id');
    if (ref_id && ld_id) {
      try {
        localStorage.setItem('ref_id', ref_id);
        localStorage.setItem('ld_id', ld_id);
        await axiosInstance.post(UPDATE_LEAD_CLK_API, {
          ld_id,
          ref_id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return {
    updateLeadClk,
  };
}
